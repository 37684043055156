import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

type Props = {
  fallback?: React.ReactNode
  children: React.ReactNode
  key: string
  status: object
  errors: object
}

export default function ComponentWrapper({
  children,
  fallback,
  key,
  status,
  errors,
}: Props) {
  const hasError = errors[key]
  const isLoading = status[key] === 'loading'

  if (isLoading) {
    return (
      <div className='flex flex-col space-y-3'>
        <div className='space-y-2'>
          <Skeleton className='h-4 w-[250px]' />
          <Skeleton className='h-4 w-[200px]' />
        </div>
      </div>
    )
  }

  if (hasError && !!fallback) {
    return <div>{fallback}</div>
  }

  if (hasError) {
    return <div>Something went wrong</div>
  }

  return <div>{children}</div>
}
