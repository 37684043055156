'use client'

import useUser from '@/api/hooks/useUser'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import ComponentWrapper from '@/components/global/ComponentWrapper'
import useOrders from '@/api/hooks/useOrder'
import { useEffect, useState } from 'react'
import useQueryParams from '@/lib/useQueryParams'

export function SelectGlobalSource() {
  const { user, selectedClientId, ...rest } = useUser()
  const { removeQueryParams } = useQueryParams()
  const { updateGlobalSource } = useOrders()
  const sources = user?.dedicatedToClientIds || []
  const [defaultValue, setDefaultValue] = useState(selectedClientId)

  useEffect(() => {
    if (selectedClientId) {
      setDefaultValue(selectedClientId)
    }
  }, [selectedClientId])

  const handleSelection = (value: string) => {

    updateGlobalSource(value)
  }


  return (
    <ComponentWrapper key='fetchUserInformation' {...rest}>
      <Select
        defaultValue={defaultValue}
        value={defaultValue}
        onValueChange={handleSelection}
      >
        <SelectTrigger className='w-full max-w-[100px] sm:w-[180px] sm:max-w-full '>
          <SelectValue placeholder='Select a fruit' />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {sources?.map((itm: any) => (
              <SelectItem key={`${itm?.label}-${itm?.value}`} value={itm?.value}>
                {itm?.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </ComponentWrapper>
  )
}
